@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Oswald', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-neutral-100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.navbutton {
  @apply relative before:content-[''] before:absolute before:w-full before:h-0.5 before:bg-white before:origin-center before:-translate-x-2/4 before:translate-y-0 before:transition-transform before:duration-[0.3s] before:ease-[ease-in-out] before:scale-x-0 before:left-2/4 before:bottom-0 hover:before:-translate-x-2/4 hover:before:translate-y-0 hover:before:scale-x-100;
}


.inputdiv {
  @apply relative;
}
.contactinput {
  @apply w-full text-[black] text-[inherit] bg-transparent border border-b-gray-300 border-solid border-transparent;
  font-family: inherit;
}
.contactinput:focus {
  outline: none;
}
.contactinput::placeholder {
  @apply text-[hsla(0,0%,100%,0.6)];
}
.contactinputspan {
  @apply absolute h-px bg-green-500 origin-bottom-right transition-transform duration-[0.5s] ease-[ease] scale-x-0 bottom-0 inset-x-0;
}
.contactinput:focus ~ .contactinputspan {
  @apply origin-bottom-left scale-x-100;
}



.textareadiv {
  @apply relative;
}
.tarea {
  @apply w-full text-[black] text-[inherit] bg-transparent focus:border-none border border-b-gray-300 transition-[background-color] duration-[0.3s] ease-[ease-in-out] px-[0.45em] py-[0.35em] border-solid border-transparent;
  font-family: inherit;
}
.tarea:focus {
  outline: none;
}
.tarea::placeholder {
  @apply text-[hsla(0,0%,100%,0.6)];
}
.tareaspan {
  @apply absolute bg-green-500 transition-transform duration-[0.1s] ease-[ease];
}
.tareabottom,
.tareatop {
  @apply h-px scale-x-0 inset-x-0;
}
.tarealeft,
.tarearight {
  @apply w-px scale-y-0 inset-y-0;
}
.tareabottom {
  @apply origin-bottom-right bottom-0;
}
.tarea:focus ~ .tareabottom {
  @apply origin-bottom-left scale-x-100;
}
.tarearight {
  @apply origin-top-right delay-[0.05s] right-0;
}
.tarea:focus ~ .tarearight {
  @apply origin-bottom-right scale-y-100;
}
.tareatop {
  @apply origin-top-left delay-[0.15s] top-0;
}
.tarea:focus ~ .tareatop {
  @apply origin-top-right scale-x-100;
}
.tarealeft {
  @apply origin-bottom-left delay-[0.25s] left-0;
}
.tarea:focus ~ .tarealeft {
  @apply origin-top-left scale-y-100;
}




@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  display: flex;
  white-space: nowrap;
  animation: scroll 10s linear infinite;
}

.article-h{
  @apply text-4xl lg:text-5xl;
}

.article-p{
  @apply lg:text-lg
}

.name-clamp{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Number of lines to show */
  overflow: hidden;
  text-overflow: ellipsis;
}